import { Typography, useDesignSystemTheme } from '@databricks/design-system';

import React from 'react';
import { Dash } from 'plotly.js';

const STROKE_WIDTH = 3;

/**
 * Replicating plotly.js's dasharrays for each dash type, with smaller spaces
 * https://github.com/plotly/plotly.js/blob/master/src/components/drawing/index.js#L162
 */
const getDashArray = (dashType: Dash) => {
  switch (dashType) {
    case 'dot':
      return `${STROKE_WIDTH}`;
    case 'dash':
      return `${2 * STROKE_WIDTH}, ${STROKE_WIDTH}`;
    case 'longdash':
      return `${3 * STROKE_WIDTH}, ${STROKE_WIDTH}`;
    case 'dashdot':
      return `${2 * STROKE_WIDTH}, ${STROKE_WIDTH}, ${STROKE_WIDTH}, ${STROKE_WIDTH}`;
    case 'longdashdot':
      return `${3 * STROKE_WIDTH}, ${STROKE_WIDTH}, ${STROKE_WIDTH}, ${STROKE_WIDTH}`;
    default:
      return '';
  }
};

export type LegendLabelData = {
  label: string;
  color: string;
  dashStyle?: Dash;
};
const TraceLabel: React.FC<LegendLabelData> = ({ label, color, dashStyle }) => {
  const { theme } = useDesignSystemTheme();

  const strokeDasharray = dashStyle ? getDashArray(dashStyle) : undefined;
  const pathYOffset = theme.typography.fontSizeSm / 2;

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        flexShrink: 0,
        marginRight: theme.spacing.md,
        maxWidth: '100%',
      }}
    >
      <svg
        css={{
          height: theme.typography.fontSizeSm,
          width: STROKE_WIDTH * 8,
          marginRight: theme.spacing.xs,
          flexShrink: 0,
        }}
      >
        <path
          d={`M0,${pathYOffset}h${STROKE_WIDTH * 8}`}
          style={{
            strokeWidth: STROKE_WIDTH,
            stroke: color,
            strokeDasharray,
          }}
        />
      </svg>
      <Typography.Text
        color="secondary"
        size="sm"
        css={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {label}
      </Typography.Text>
    </div>
  );
};

type RunsMetricsLegendProps = {
  labelData: LegendLabelData[];
};

const RunsMetricsLegend: React.FC<RunsMetricsLegendProps> = ({ labelData }) => {
  const { theme } = useDesignSystemTheme();

  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        height: theme.spacing.md * 2,
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginTop: theme.spacing.sm,
      }}
    >
      {labelData.map((labelDatum) => (
        <TraceLabel key={labelDatum.label} {...labelDatum} />
      ))}
    </div>
  );
};

export default RunsMetricsLegend;
